import {
  CSS_CURSOR_POINTER,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'
import React, { memo } from 'react'

import ShopItem, { ShopItemsPanel } from './ShopItems'
import { openBlankRedirectPage } from '../../../util/app/app.helpers'

const EarnRewardCards = ({ data, t, fullWidth }) => {
  const isDataLengthOdd = data?.length % 2 !== 0
  return (
    <ShopItemsPanel fullWidth={fullWidth}>
      {data.map(({ label, description, image, link }, index) => (
        <ShopItem
          key={index}
          label={t(label)}
          description={description}
          fullColumn={isDataLengthOdd && index === 0}
          bgImage={image}
          onClick={link ? () => openBlankRedirectPage(link) : null}
          bgImageOpacity={70}
          customCss={[link && CSS_CURSOR_POINTER, fullWidth && CSS_FULL_WIDTH]}
        />
      ))}
    </ShopItemsPanel>
  )
}

export default memo(EarnRewardCards)
